<template>
  <div v-if="this.categoryID !== undefined">
    <div v-if="this.categoryID.child.length === 0">
      <img
        :src="this.categoryID.catInfo.thumb"
        width="100%"
        height="100.001px"
      />
    </div>

    <div class="catlist">
      <!-- catid -->
      <div v-for="subcatid in this.categoryID.child" :key="subcatid.id">
        <Cat :cat="subcatid"></Cat>
      </div>
      <!-- thisarticle -->
      <div v-for="aricle in this.categoryID.thisarticle" :key="aricle.id">
        <div class="zylist" @click="toArticleID(aricle.id)">
          <van-row>
            <van-col span="24">
              {{ aricle.title }}
            </van-col>
          </van-row>
          <div class="time">
            <van-row>
              <van-col span="14"
                ><van-icon name="manager" /> {{ aricle.views }} 人次</van-col
              >
              <van-col span="10">{{ aricle.atime }}</van-col>
            </van-row>
          </div>
        </div>
      </div>
    </div>
    <div class="time" v-if="this.categoryID.thisarticle.length === 0">
      暂时没有文章。
    </div>
  </div>
</template>

<script>
// import NavbarCommon from '@/components/common/NavbarCommon'
import Cat from '@/components/article/Cat'
export default {
  components: {
    Cat
  },
  props: ['categoryID'],
  beforeMount () {
    // this.$store.commit('hideShowTabbar', false)
  },

  data () {
    return {
      back: 'skillful'
    }
  },
  mounted () {
    console.log(this.categoryIDs, 'categoryIDs.vue')
    // if (this.categoryID === undefined) {
    //   this.categoryID = JSON.parse(localStorage.getItem('categoryID'))
    // } length
    if (this.categoryID === null) {
      this.$router.push('/skillful')
    }
  },
  methods: {
    toArticleID (id) {
      console.log(id)

      if (this.article === undefined || (typeof this.article === 'object' && this.article.id !== id)) {
        this.$store.commit('unsetArticleInfo', '')
        localStorage.setItem('article', '')
        // this.$store.commit('unsetPointslist', '')
        console.log('清除 Article')
        this.$store.dispatch('getArticleInfo', {
          articleid: id
        })
        //   console.log(this.$store.state.article.catlist, 'state catlist')
        console.log('重新请求 Article')
      }
      id = ''
      localStorage.setItem('articleId', id)
      this.$router.push('/article')
      //   this.$rout.article
    }
  },
  computed: {
    article () {
      let article = this.$store.state.article.article
      if (article === null) {
        article = []
      }
      //   console.log(article, 'article')
      if (article !== []) {
        return article
      } else {
        article = localStorage.getItem('article')
      }
      var articleArr = []
      if (article !== null) {
        articleArr = JSON.parse(article)
      }
      return articleArr// .article
    }
    // categoryIDs () {
    //   let categoryID = this.$store.state.article.catid
    //   if (categoryID === null) {
    //     categoryID = []
    //   }
    //   //   console.log(categoryID, 'dddd')
    //   if (categoryID !== []) {
    //     return categoryID
    //   } else {
    //     categoryID = localStorage.getItem('categoryID')
    //   }
    //   var categoryIDArr = []
    //   if (categoryID !== null) {
    //     categoryIDArr = JSON.parse(categoryID)
    //   }
    //   return categoryIDArr// .courselist
    // }
  }
  //   watch: {
  //     categoryID () {
  //       this.categoryID = JSON.parse(localStorage.getItem('categoryID'))
  //     }
  //   }

}
</script>
<style lang="scss" scoped>
li,
div,
img {
  margin: 0vw;
  padding: 0vw;
}
.zylist {
  color: rgb(78, 34, 5);
  font-size: 3.611vw;
  width: 100;
  padding-left: 1.389vw;
  padding-top: 1.833vw;
  padding-bottom: 1.833vw;
  border-bottom: 0.278vw dotted chocolate;
  text-align: left;
}
.time {
  color: rgb(150, 146, 140);
  padding-left: 5.556vw;
  font-size: 3.333vw;
}
.fontbold {
  font-weight: bold;
}
</style>
