<template>
  <div v-if="categoryID">
    <NavbarCommon :title="this.catename" :back="back"></NavbarCommon>
    <Categoryid :categoryID="this.categoryID"></Categoryid>

    <!-- <div class="catlist" @click="toArtileid(categoryID.id)">
      <div v-for="subcatid in categoryID.child" :key="subcatid.id">
        <div class="catlist-top">
          <van-tag type="danger">{{ subcatid.cate_name }} </van-tag>
        </div>
        {{ subcatid.cate_name }} -->
    <!-- <img v-if="categoryID.thumb" :src="cat.thumb" alt="" />
      <img v-else src="@/assets/logo.png" width="100%" height="125vw" /> -->
    <!-- </div> -->

    <!-- <div class="catlist-top">
        <van-tag type="danger">{{ cat.cate_name }} </van-tag>
      </div> -->
    <!-- <div class="catlist-bottom">
        <div class="catlist-title">
          {{ cat.cate_name }}
        </div>
      </div> -->

    <!-- <van-row>
        <van-col span="24"> </van-col>
      </van-row>
      <div class="course-top">
        <van-tag type="danger">{{ cat.catname }} </van-tag>
      </div>
      <div class="course-bottom">
        <div class="course-title">
          {{ cat.cate_name }}
        </div>
      </div> -->
    <!-- </div> -->
  </div>
  <van-loading v-else size="24px" color="#0094ff" vertical class="margintop"
    >资源加载中...</van-loading
  >
</template>

<script>
import NavbarCommon from '@/components/common/NavbarCommon'
import Categoryid from '@/components/article/Categoryid'
export default {
  components: {
    NavbarCommon, Categoryid
  },
  //   props: ['cat'],
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },

  data () {
    return {
      back: 'skillfull',
      title: '文章栏目'
      //   categoryID: ''
    }
  },
  mounted () {
    console.log(this.categoryID, 'View  categoryID.vue')
    // this.categoryID = localStorage.getItem('categoryID')
  },
  methods: {
    // toCatid (catid) {
    //   console.log(catid)
    // }
  },
  computed: {
    categoryID () {
      let categoryID = this.$store.state.article.catid
      if (categoryID === null) {
        categoryID = []
      }
      //   console.log(categoryID, 'dddd')
      if (categoryID !== []) {
        return categoryID
      } else {
        categoryID = localStorage.getItem('categoryID')
      }
      var categoryIDArr = []
      if (categoryID !== null) {
        categoryIDArr = JSON.parse(categoryID)
      }
      return categoryIDArr// .courselist
    },
    catename: {
      get () {
        if (this.categoryID.catInfo === undefined) {
          return this.title
        } else {
          return this.categoryID.catInfo.cate_name
        }
      }
    }
  }

}
</script>
<style lang="scss" scoped>
li,
div,
img {
  margin: 0vw;
  padding: 0vw;
}
.catlist {
  margin: 0vw;
  //   padding: 5px;
  //   margin-bottom: -2.778vw;
  //   background-color: aqua;
  //   overflow: hidden;
  position: relative;
  float: left;
  //   clear: both;
  width: 50%;
  img {
    width: 96%;
    // width="100%"
    height: 27.778vw;
    padding-top: 1.389vw;
  }
  //   background-color: black;
  .catlist-top {
    position: absolute;
    // background-color: aquamarine;
    top: 2.778vw;
  }
  .catlist-bottom {
    position: absolute;
    bottom: 0vw;
    height: 11.111vw;
    padding-left: 2.778vw;
    width: 50%;
    .catlist-title {
      color: rgb(100, 9, 9);
      font-size: 3.611vw;
      font-weight: bold;
      padding: 0.556vw;
    }
    .van-card__title {
      font-size: 3.056vw;
      color: rgb(97, 14, 14);
      padding-left: 5.556vw;
      padding-top: 1.389vw;
    }
  }
}
.margintop {
  margin-top: 5.556vw;
}
</style>
